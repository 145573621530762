<template>
    <div class="profile">
        <div 
            v-if="loading" 
            class="flex justify-center pt-5">
            <a-spin />
        </div>
        <div 
            v-else>
            <div class="profile_menu truncate">
                <template v-if="profileMenu.length">
                    <div 
                        v-if="user" 
                        class="user text-center">
                        <div class="flex justify-center relative">
                            <label for="avatar_upload" class="cursor-pointer">
                                <a-avatar 
                                    :size="100" 
                                    :key="user.avatar ? user.avatar.path : false"
                                    :src="user.avatar && user.avatar.path"
                                    icon="user" />
                                <div class="avatar_edit__icon" title="Изменить аватар">
                                    <div class="ant-btn ant-btn-circle ant-btn-icon-only flex items-center justify-center">
                                        <i class="fi fi-rr-cloud-upload-alt"></i>
                                    </div>
                                </div>
                            </label>
                            
                            <input
                                type="file"
                                id="avatar_upload"
                                style="display:none;"
                                ref="avatarUpload"
                                v-on:change="handleFileChange"
                                accept=".jpg, .jpeg, .png, .gif" />
                        
                        </div>
                        <div class="user_name mt-3 font-semibold text-xl">
                            {{ userName }}
                        </div>
                        <div 
                            v-if="user.email" 
                            class="user_email font-light">
                            {{ user.email }}
                        </div>
                    </div>
                    <div class="menu">
                        <div 
                            v-for="item in profileMenu" 
                            :key="item.path" 
                            class="item truncate select-none"
                            :class="item.path === active && 'active'"
                            @click="selectMenu(item)">
                            <i :class="`fi ${item.icon}`"></i>
                            <span class="truncate whitespace-nowrap">
                                {{ item.name }}
                            </span>
                        </div>
                        <div
                            v-if="support"
                            class="item truncate select-none justify-between"
                            @click="supportVisible = true">
                            <div class="flex items-center">
                                <i class="fi fi-rr-interrogation"></i>
                                <span class="truncate whitespace-nowrap">
                                    Справка
                                </span>
                            </div>
                            <a-badge :count="unreadCount" :number-style="{ backgroundColor: '#52c41a' }" />
                        </div>
                        <div 
                            class="item truncate select-none"
                            @click="otherVisible = true">
                            <i class="fi fi-rr-exclamation"></i>
                            <span class="truncate whitespace-nowrap">
                                Прочее
                            </span>
                        </div>
                        <div 
                            class="item truncate select-none text_red"
                            @click="logOut()">
                            <i class="fi fi fi-rr-power"></i>
                            <span class="truncate whitespace-nowrap">
                                {{ $t('exit') }}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
            <a-drawer
                title=""
                placement="bottom"
                :visible="visible"
                :zIndex="1000"
                class="profile_drawer"
                :afterVisibleChange="afterVisibleChange"
                :destroyOnClose="true"
                @close="closeMenuDrawer()">
                <div 
                    v-if="activeItem" 
                    :key="active" 
                    class="profile_drawer_body">
                    <div>
                        <h1>
                            {{ activeItem.name }}
                        </h1>
                        <TabsSwitch :activeItem="activeItem" />
                    </div>
                </div>
            </a-drawer>
        </div>

        <a-drawer
            title=""
            placement="bottom"
            width="100%"
            height="100%"
            :zIndex="99999"
            destroyOnClose
            class="cropper_modal"
            :visible="cropModal"
            @close="closeCropModal()">
            <div class="cr_d_body">
                <div v-if="dataUrl" class="relative h-full">
                    <img
                        ref="avatarImg"
                        @load.stop="createCropper"
                        :src="dataUrl" />

                    <div class="action_btn flex items-center">
                        <a-button 
                            type="ui"
                            icon="fi-rr-rotate-left" 
                            flaticon
                            shape="circle"
                            @click="cropper.rotate(-45)" />
                        <a-button 
                            type="ui"
                            class="ml-1" 
                            flaticon
                            shape="circle"
                            icon="fi-rr-rotate-right"
                            @click="cropper.rotate(45)"  />
                    </div>
                </div>
            </div>
            <div class="cr_d_footer">
                <a-button type="primary" size="large" block @click="uploadImage()" class="mb-2" :loading="uploadLoading">
                    Загрузить
                </a-button>
                <a-button type="ui" ghost block size="large" @click="closeCropModal()">
                    {{$t('close')}}
                </a-button>
            </div>
        </a-drawer>

        <a-drawer
            placement="bottom"
            :visible="otherVisible"
            class="activity_views other_us_menu"
            height="auto"
            :destroyOnClose="true"
            @close="otherVisible = false">
            <menu>
                <li>
                    <div 
                        class="link" 
                        @click="cacheClear()">
                        <i class="fi fi-rr-refresh icon"></i>
                        <span>Очистить кэш</span>
                    </div>
                </li>
                <li>
                    <div 
                        class="link" 
                        @click="openVersion()">
                        <i class="fi fi-rr-interrogation icon"></i>
                        <span>Версия клиента</span>
                    </div>
                </li>
            </menu>
        </a-drawer>

        <a-drawer
            v-if="support"
            placement="bottom"
            :visible="supportVisible"
            class="activity_views other_us_menu"
            height="auto"
            :destroyOnClose="true"
            @close="supportVisible = false">
            <menu>
                <li>
                    <div 
                        class="link justify-between" 
                        @click="portalNews()">
                        <div class="flex items-center">
                            <i class="fi fi-rr-megaphone icon"></i>
                            <span>Лента новостей</span>
                        </div>
                        <a-badge :count="unreadCount" :number-style="{ backgroundColor: '#52c41a' }" />
                    </div>
                </li>
                <li>
                    <div 
                        class="link" 
                        @click="openHelp()">
                        <i class="fi fi-rr-messages-question icon"></i>
                        <span>Справка</span>
                    </div>
                </li>
                <!--<li>
                    <div 
                        class="link" 
                        @click="openSupportChat()">
                        <i class="fi fi-rr-paper-plane icon"></i>
                        <span>Чат технической поддержки</span>
                    </div>
                </li>-->
            </menu>
        </a-drawer>

        <a-modal
            title="Версия клиента"
            :destroyOnClose="true"
            :visible="versionModal"
            @cancel="versionModal = false">
            <div class="mb-3">
                <strong class="mr-2">Хост:</strong>
                <span>
                    {{ host }}
                </span>
            </div>
            <div class="mb-3">
                <strong class="mr-2">Версия клиента:</strong>
                <span>
                    {{ version }}
                </span>
            </div>
            <div v-if="builddata">
                <strong class="mr-2">Дата билда:</strong>
                <span>
                    {{ builddata }}
                </span>
            </div>
            <template slot="footer">
                <a-button 
                    type="default" 
                    @click="versionModal = false">
                    Закрыть
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import TabsSwitch from './Tabs/TabsSwitch.vue'
import eventBus from '@/utils/eventBus.js'
import {checkImageWidthHeight, hashString, getFileExtension} from '@/utils/utils'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
export default {
    components: {
        TabsSwitch,
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            config: state => state.config.config,
            pushAuth: state => state.pushAuth,
            profileMenu: state => state.user.profileMenu,
            windowWidth: state => state.windowWidth,
            serverType: state => state.serverType,
            dbList: state => state.dbList
        }),
        myPoints() {
            return this.config?.order_setting?.myPoints || false
        },
        support() {
            return this.config?.header_setting?.support || false
        },
        visible: {
            get() {
                // return true
                return this.$store.state.user.settingVisible
            },
            set(val) {
                this.$store.commit('user/SETTING_DRAWER_TOGGLE', val)
            }
        },
        userName() {
            if(this.user.first_name)
                return `${this.user.first_name} ${this.user.last_name && this.user.last_name.substr(0, 1)}`
            else
                return this.user.username
        },
        activeItem() {
            if(this.active && this.profileMenu?.length) {
                const find = this.profileMenu.find(f => f.path === this.active)
                if(find)
                    return find
                else
                    return null
            } else
                return null
        }
    },
    data() {
        return {
            active: '',
            loading: false,
            cropModal: false,
            otherVisible: false,
            supportVisible: false,
            versionModal: false,
            unreadCount: 0,
            cropperOptions: {
                aspectRatio: 1 / 1,
                minCropBoxWidth: 100,
                minCropBoxHeight: 100
            },
            uploadLoading: false,
            file: null,
            dataUrl: null,
            minSize: 100,
            avatarLoader: false,
            sAvatar: '',
            host: process.env.VUE_APP_URL,
            version: process.env.VUE_APP_VERSION,
            builddata: process.env.VUE_APP_BUILD_DATE
        }
    },
    watch: {
        '$route.query'(val) {
            if(val?.menu_page )
                this.openDrawer()
        }
    },
    created () {
        this.getMenu()

        if(this.$route.query?.menu_page)
            this.openDrawer()

        if(this.support)
            this.getNewsCount()
    },
    methods: {
        async getNewsCount() {
            try {
                const { data } = await this.$http.get('/news/news/unread_count/')
                if(data?.unread_count) {
                    this.unreadCount = data.unread_count
                }
            } catch(e) {
                console.log(e)
            }
        },
        changeCount() {
            if(this.unreadCount > 0) {
                this.unreadCount -= 1
            } else {
                this.unreadCount = 0
            }
        },
        portalNews() {
            this.supportVisible = false
            eventBus.$emit('open_portal_news')
        },
        openHelp() {
            this.supportVisible = false
            const query = {...this.$route.query}
            query.help = true
            this.$router.push({ query })
        },
        openSupportChat() {
            this.$router.push({ name: 'chat' })
        },
        openDeliveryPoints() {
            eventBus.$emit('open_delivery_points_drawer')
        },
        openVersion() {
            this.versionModal = true
            this.otherVisible = false
        },
        closeCropModal() {
            this.cropModal = false
            this.dataUrl = null
            this.file = null
        },
        createCropper() {
            this.cropper = new Cropper(this.$refs.avatarImg, this.cropperOptions)
        },
        async handleFileChange(event) {
            const file = Object.values(event.target.files)[0]
            if(file) {
                const fileSize = await checkImageWidthHeight(file)
                if(fileSize.width > this.minSize && fileSize.height > this.minSize) {
                    if(file.size / 1024 / 1024 < 10) {
                        let reader = new FileReader()
                        reader.onload = e => {
                            this.dataUrl = e.target.result
                        }
                        reader.readAsDataURL(file)
                        this.file = file
                        this.cropModal = true
                    } else
                        this.$message.error(this.$t('max_file_size', {size: 10}))
                } else
                    this.$message.error(this.$t('max_file_h_w', {size: this.minSize}))
            }
        },
        uploadImage() {
            this.cropper.getCroppedCanvas().toBlob(async (avatar) => {
                try {
                    const exc = getFileExtension(this.file.name),
                        filename = `${hashString(this.file.name)}.${exc}`

                    this.uploadLoading = true
                    let formData = new FormData()
                    formData.append("upload", avatar, filename)

                    const {data} = await this.$http.post('/common/upload/', formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    if(data) {
                        const res = await this.$http.post('users/change_avatar/', { 
                            avatar: data[0].id
                        })

                        if(res) {
                            this.$store.commit("user/SET_AVATAR", data[0])
                            this.$message.success(this.$t('success_avatar'))
                            this.closeCropModal()
                        }
                    }
                } catch(e) {
                    this.$message.error(this.$t('error'))
                } finally {
                    this.uploadLoading = false
                }
            })
        },
        async getMenu() {
            try {
                this.loading = true
                await this.$store.dispatch('user/getProfileMenu')
                this.setStartActive()
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        setStartActive() {
            if(this.$route.query?.menu_page)
                this.active = this.$route.query.menu_page
        },
        openDrawer() {
            this.visible = true
        },
        closeMenuDrawer() {
            this.visible = false
            this.active = ''
        },
        selectMenu(item) {
            if(item.path !== this.active) {
                this.active = item.path

                const query = JSON.parse(JSON.stringify(this.$route.query))
                query.menu_page = item.path
                this.$router.push({query})
            }
        },
        close() {
            const query = Object.assign({}, this.$route.query)
            if(query.menu_page)
                delete query.menu_page
            this.$router.push({query})
            this.setStartActive()
        },
        afterVisibleChange(val) {
            if(!val) {
                this.close()
                this.active = ''
            }
        },

        async cacheClear2() {
            try {
                this.loading = true
                await this.$http.get('/app_info/update_front_cache/')
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
                location.reload()
            }
        },


        // LOGOUT 
        async cacheClear(reload = true) {
            try {
                this.loading = true
                for(let key in this.dbList) {
                    await this.deleteDb(this.dbList[key])
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false

                if(reload)
                    location.reload()
            }
        },
        deleteDb(name) {
            return new Promise((resolve, reject) => {
                const req = indexedDB.deleteDatabase(name)
                req.onerror = () => {
                    reject(false)
                }

                req.onsuccess = () => {
                    resolve(true)
                }

                req.onblocked = () => {
                    resolve(true)
                    console.log("Couldn't delete database due to the operation being blocked")
                }
            })
        },
        async pushUnrigister() {
            if(this.pushAuth) {
                try {
                    const payload = {
                        subscription: {
                            keys: {
                                auth: this.pushAuth
                            }
                        },
                        uid: this.user.id
                    }
                    await this.$http2.post('/subscribe/delete/', payload)
                } catch(e) {
                    console.log(e)
                }
            }
        },
        async logOut() {
            try {
                this.loading = true
                await this.pushUnrigister()
                await this.$store.dispatch('user/logout')
                await this.cacheClear(false)
                // this.$router.push({name: 'login'})
                location.reload()
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            } finally {
                this.loading = false
            }
        }
    },
    mounted() {
        eventBus.$on('read_news_count', () => {
            this.changeCount()
        })
    },
    beforeDestroy() {
        eventBus.$off('read_news_count')
    }
}
</script>

<style lang="scss" scoped>
$fullscreen: calc(var(--vh, 1vh) * 100);
.cropper_modal{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-header-no-title{
            display: none;
        }
        .ant-drawer-body{
            height: 100%;
            padding: 0px;
        }
        .cr_d_body{
            height: calc(100% - 100px);
        }
        .action_btn{
            position: absolute;
            bottom: 10px;
            right: 15px;
        }
        .cr_d_footer{
            height: 100px;
            border-top: 1px solid var(--border1);
            padding: 5px 15px;
        }
        .cropper-face{
            border-radius: 50%;
        }
        .cropper-view-box {
            border-radius: 50%;
        }
    }
}
.profile{
    height: 100%;
    overflow-y: auto;
    .profile_menu{
        padding: 15px;
        .menu{
            background: #fff;
            border-radius: var(--borderRadius);
            margin-top: 20px;
            .item{
                display: flex;
                align-items: center;
                padding: 15px 0px;
                font-size: 18px;
                cursor: pointer;
                transition: background-color 0.3s ease;
                font-weight: 300;
                &:not(:last-child){
                    border-bottom: 1px solid var(--borderColor);
                }
                &.active{
                    color: var(--blue);
                }
                .fi{
                    margin-right: 15px;
                    font-size: 22px;
                }
            }
        }
    }
 
}
.profile_drawer_body{
    height: 100%;
    padding: 15px;
    overflow-y: scroll;
    h1{
        font-weight: 300;
        font-size: 24px;
        margin-bottom: 20px;
    }
}
.user {
    max-width: 320px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.avatar_edit__icon{
    position: absolute;
    top: 60%;
    right: 30%;
}
.other_us_menu{
    menu{
        width: 100%;
        padding: 0px;
        margin: 0px;
        li{
            list-style: none;
            color: #000;
            .link{
                display: flex;
                align-items: center;
                font-weight: 300;
                background: rgba(255, 255, 255, 0.8);
                padding: 13px 15px;
                -webkit-backdrop-filter: saturate(180%) blur(20px);
                backdrop-filter: saturate(180%) blur(20px);
                font-size: 17px;
                .icon{
                    margin-right: 10px;
                }
            }
            &:last-child{
                .link{
                    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
                }
            }
            &:first-child{
                .link{
                    border-radius: var(--borderRadius) var(--borderRadius) 0px 0px;
                }
            }
            &:not(:last-child){
                margin-bottom: 0px!important;
            }
        }
    }
}
.profile_drawer{
    &::v-deep{
        .ant-drawer-content-wrapper {
            height: 100% !important;
        }
        .ant-drawer-body{
            padding: 0px;
        }
    }
}
</style>
